import type { UUID } from "node:crypto"

import { isValidPhoneNumber } from "libphonenumber-js"
import { z } from "zod"

import { commonConfig } from "@cyna/common/config"
import {
  BOOLEANS_AS_STRING,
  BUSINESS_SECTORS,
  COMPANY_SIZE,
  EDR_NAMES,
  ORDER_BY_DIRECTION,
  REPORT_TYPES,
  ROLES,
  S1_MSP_ROLES,
  S1MspRolesType,
  SERVICE_NAMES,
  ServiceName,
  SERVICES_STATUS,
  SUPPORTED_LANGUAGES,
} from "@cyna/common/constants"

z.setErrorMap((issue) => {
  const validation =
    (issue as { validation: string } | undefined)?.validation ?? "generic"

  return {
    message: `common:errors.validation.${validation}.${issue.code}`,
  }
})

export const uuidValidator = z
  .string()
  .uuid()
  .toLowerCase()
  // Dirty type fix
  .transform((x) => x as UUID)

export const idValidator = uuidValidator

export const timestampValidator = z.coerce.date()

export const fileUriValidator = z.string().regex(/^(?:\/[a-z0-9_.-]+)+$/iu)

export const reportTypeValidator = z.nativeEnum(REPORT_TYPES)

export const nameValidator = z.string()

export const companyNameValidator = z.string()

export const emailValidator = z.string().email().toLowerCase()

export const hashValidator = z.string().regex(/^[a-f0-9]+$/iu)

export const languageValidator = z.enum(SUPPORTED_LANGUAGES)

export const roleValidator = z.nativeEnum(ROLES)

export const serviceNameValidator = z.enum([
  SERVICE_NAMES.MS365,
  SERVICE_NAMES.MS365_DEFENDER,
  SERVICE_NAMES.S1_INTERNAL,
])

export const serviceNameArrayValidator = z.array(serviceNameValidator)

export const userServicesValidator = z.enum([SERVICE_NAMES.S1_INTERNAL])

export const serviceStatusValidator = z.enum(
  Object.keys(SERVICES_STATUS) as [ServiceName, ...ServiceName[]],
)

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])

type Literal = z.infer<typeof literalSchema>

export type JsonType =
  | Literal
  | { [key: string]: JsonType | Literal }
  | Array<JsonType | Literal>

export const jsonValidator: z.ZodType<JsonType> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonValidator), z.record(jsonValidator)]),
)

export const serviceDataValidator = jsonValidator

export const paginationValidator = z.object({
  pageSize: z.coerce
    .number()
    .min(1)
    .max(commonConfig.app.maxItemsPerPage)
    .default(commonConfig.app.itemsPerPage),
  pageIndex: z.coerce.number().min(0).default(0),
})

export const passwordValidator = z
  .string()
  .regex(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS1501 We are using es6+ as a target...
    /(?=.*\d)(?=.*[^\p{L}\d])(?=.*\p{Ll})(?=.*\p{Lu})/gu,
    "common:errors.validation.password.pattern",
  )
  .min(10)

export const phoneValidator = z
  .string()
  .refine((val) => !val || isValidPhoneNumber(val), {
    message: "common:errors.validation.phone.invalid",
  })

export const portValidator = z.coerce.number().min(1001).max(65535)

// Useful to validate non-empty strings ("" is a valid string)
export const stringValidator = z.string().superRefine((str, ctx) => {
  if (str === "") {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "common:errors.validation.string.required",
    })
  }
})

export const numberValidator = z.number()

export const urlValidator = z.string().url()

export const totpSecretValidator = z.string()

export const totpCodeValidator = z
  .string({ message: "common:errors.validation.totp.invalid" })
  .min(6, "common:errors.validation.totp.too_small")

export const companySlugValidator = z
  .string()
  .transform((name) =>
    name
      .toLowerCase()
      // Breaks accentuated characters into accent character and letter character.
      .normalize("NFD")
      // Removes all accent characters.
      .replaceAll(/[\u0300-\u036f]/gu, "")
      .replaceAll(/[^a-z0-9]+/gu, "_")
      .replaceAll(/(^_)|(_$)/gu, ""),
  )
  .pipe(z.string().regex(/^[a-z]+[_a-z0-9]*[a-z0-9]$/gu))

export const createOrderByValidator = <T extends string>(
  allowedKeys: [T, ...T[]],
  defaultKey: T,
) =>
  z.object({
    orderByKey: z.enum(allowedKeys).default(defaultKey),
    orderByDirection: z
      .nativeEnum(ORDER_BY_DIRECTION)
      .default(ORDER_BY_DIRECTION.DESC),
  })

export const edrTypeValidator = z.nativeEnum(EDR_NAMES)

export const createFileValidator = ({
  mimeTypes,
  minSize,
  maxSize,
}: {
  mimeTypes: [string, ...string[]]
  minSize: number
  maxSize: number
}) => {
  const fileAttributeValidator = z.object({
    type: z.enum(mimeTypes),
    size: z.number().min(minSize).max(maxSize),
  })

  return z.instanceof(File).superRefine((file, ctx) => {
    const { error } = fileAttributeValidator.safeParse(file)

    if (error) {
      error.issues.forEach((issue) => {
        ctx.addIssue(issue)
      })
    }
  })
}

const samePasswordSchema = z.object({
  password: passwordValidator,
  confirmationPassword: stringValidator,
})
const passwordMatchValidator = (
  schema: z.ZodObject<{
    password: typeof passwordValidator
    confirmationPassword: typeof stringValidator
    oldPassword?: typeof passwordValidator
  }>,
) =>
  schema.superRefine((data: z.infer<typeof schema>, ctx) => {
    if (data.password !== data.confirmationPassword) {
      ctx.addIssue({
        code: "custom",
        message: "common:errors.validation.password.mismatch",
        path: ["confirmationPassword"],
      })
    }
  })

export const samePasswordCheckValidator =
  passwordMatchValidator(samePasswordSchema)

const changePasswordSchema = samePasswordSchema.merge(
  z.object({
    oldPassword: passwordValidator,
  }),
)

export const changePasswordCheckValidator =
  passwordMatchValidator(changePasswordSchema)

export const booleansInStringToBoolean = z
  .nativeEnum(BOOLEANS_AS_STRING)
  .transform((arg: string) => JSON.parse(arg.toLowerCase()) as boolean)

export const isOnCallDutyValidator = z.boolean().default(false)

export const registrationNumberValidator = z.string()

export const companySizeValidator = z.nativeEnum(COMPANY_SIZE)

export const businessSectorValidator = z.nativeEnum(BUSINESS_SECTORS)

export const mspS1RoleValidator = z.enum(
  Object.keys(S1_MSP_ROLES) as [S1MspRolesType, ...S1MspRolesType[]],
)

export const s1ManagementUrlValidator = z
  .string()
  .url()
  .regex(/(https:\/\/\S+sentinelone.net)?/gsu)

export const stringArrayValidator = z.array(z.string())
