/* eslint-disable max-classes-per-file */
import { HTTP_CODES, HttpCode } from "@cyna/common/constants"

export const GENERICS_ERRORS = {
  BAD_REQUEST: {
    message: `common:errors.api.${HTTP_CODES.BAD_REQUEST}`,
    code: HTTP_CODES.BAD_REQUEST,
  },
  UNAUTHORIZED: {
    message: `common:errors.api.${HTTP_CODES.UNAUTHORIZED}`,
    code: HTTP_CODES.UNAUTHORIZED,
  },
  FORBIDDEN: {
    message: `common:errors.api.${HTTP_CODES.FORBIDDEN}`,
    code: HTTP_CODES.FORBIDDEN,
  },
  NOT_FOUND: {
    message: `common:errors.api.${HTTP_CODES.NOT_FOUND}`,
    code: HTTP_CODES.NOT_FOUND,
  },
  INTERNAL_SERVER_ERROR: {
    message: `common:errors.api.${HTTP_CODES.INTERNAL_SERVER_ERROR}`,
    code: HTTP_CODES.INTERNAL_SERVER_ERROR,
  },
  UNPROCESSABLE_ENTITY: {
    message: `common:errors.api.${HTTP_CODES.UNPROCESSABLE_ENTITY}`,
    code: HTTP_CODES.UNPROCESSABLE_ENTITY,
  },
  CONFLICT: {
    message: `common:errors.api.${HTTP_CODES.CONFLICT}`,
    code: HTTP_CODES.CONFLICT,
  },
} as const

export const TOTP_ERRORS = {
  TOTP_MISSING_SECRET: {
    message: "apiErrors:totp.missingSecret",
    code: HTTP_CODES.UNAUTHORIZED,
  },
  TOTP_INVALID_PASSWORD: {
    message: "apiErrors:totp.invalidPassword",
    code: HTTP_CODES.BAD_REQUEST,
  },
  TOTP_INVALID_CODE: {
    message: "apiErrors:totp.invalidCode",
    code: HTTP_CODES.BAD_REQUEST,
  },
  TOTP_NOT_ENABLED: {
    message: "apiErrors:totp.notEnabled",
    code: HTTP_CODES.BAD_REQUEST,
  },
} as const

export const AUTH_ERRORS = {
  AUTH_INVALID_CREDENTIALS: {
    message: "apiErrors:auth.invalidCredentials",
    code: HTTP_CODES.UNAUTHORIZED,
  },
  AUTH_MISSING_AUTHORIZATION_HEADER: {
    message: "apiErrors:auth.missingAuthorizationHeader",
    code: HTTP_CODES.FORBIDDEN,
  },
  AUTH_INVALID_SESSION: {
    message: "apiErrors:auth.invalidSession",
    code: HTTP_CODES.UNAUTHORIZED,
  },
  AUTH_COOKIE_NOT_FOUND: {
    message: "apiErrors:auth.cookieNotFound",
    code: HTTP_CODES.UNAUTHORIZED,
  },
} as const

export const SERVICES_ERRORS = {
  S1_INTERNAL_ENABLE_USERS_NOT_FOUND: {
    message: "apiErrors:services.S1_INTERNAL.enable.usersNotFound",
    code: HTTP_CODES.BAD_GATEWAY,
  },
  S1_INTERNAL_ENABLE_POLICY_CREATION_FAILED: {
    message: "apiErrors:services.S1_INTERNAL.enable.policyCreationFailed",
    code: HTTP_CODES.BAD_GATEWAY,
  },
  S1_INTERNAL_ENABLE_ROLES_NOT_FOUND: {
    message: "apiErrors:services.S1_INTERNAL.enable.rolesNotFound",
    code: HTTP_CODES.BAD_GATEWAY,
  },
  S1_INTERNAL_ENABLE_GROUPS_CREATION_FAILED: {
    message: "apiErrors:services.S1_INTERNAL.enable.groupsCreationFailed",
    code: HTTP_CODES.BAD_GATEWAY,
  },
  SERVICES_REMOVING_ENABLED_SERVICE: {
    message: "apiErrors:services.removingEnabledService",
    code: HTTP_CODES.UNPROCESSABLE_ENTITY,
  },
} as const

export const SERVICE_SECRETS_ERRORS = {
  EDR_INVALID_CREDENTIALS: {
    message: "apiErrors:serviceSecrets.invalidCredentials",
    code: HTTP_CODES.UNAUTHORIZED,
  },
  EDR_NOT_FOUND: {
    message: "apiErrors:serviceSecrets.credentialsNotFound",
    code: HTTP_CODES.NOT_FOUND,
  },
} as const

export const PERMISSION_ERRORS = {
  PERMISSION_DENIED: {
    message: "apiErrors:permission.denied",
    code: HTTP_CODES.FORBIDDEN,
  },
  PERMISSION_VALIDATION_FAILED: {
    message: "apiErrors:permission.validationFailed",
    code: HTTP_CODES.BAD_REQUEST,
  },
} as const

export const REPORTS_ERRORS = {
  SERVERS_WORKSTATIONS_NOT_INCLUDED: {
    message: "apiErrors:reports.serversWorkstationsNotIncluded",
    code: HTTP_CODES.SERVICE_UNAVAILABLE,
  },
  REPORT_GENERATION: {
    message: "apiErrors:reports.generation",
    code: HTTP_CODES.INTERNAL_SERVER_ERROR,
  },
} as const

export const CONTACTS_ERRORS = {
  CONTACT_ALREADY_EXIST: {
    message: "apiErrors:emergencyContacts.alreadyExist",
    code: HTTP_CODES.CONFLICT,
  },
  CONTACTS_MINIMUM_AT_ONE: {
    message: "apiErrors:emergencyContacts.minimumAtOne",
    code: HTTP_CODES.FORBIDDEN,
  },
} as const

export const USERS_ERRORS = {
  USER_ALREADY_EXIST: {
    message: "apiErrors:users.alreadyExist",
    code: HTTP_CODES.CONFLICT,
  },
  USER_ALREADY_SETUP: {
    message: "apiErrors:users.alreadySetup",
    code: HTTP_CODES.CONFLICT,
  },
} as const

export const ERROR_RESPONSES = {
  ...AUTH_ERRORS,
  ...CONTACTS_ERRORS,
  ...GENERICS_ERRORS,
  ...PERMISSION_ERRORS,
  ...REPORTS_ERRORS,
  ...SERVICES_ERRORS,
  ...SERVICE_SECRETS_ERRORS,
  ...TOTP_ERRORS,
  ...USERS_ERRORS,
  ...SERVICES_ERRORS,
} as const

export type ERROR_RESPONSE_TYPES = keyof typeof ERROR_RESPONSES

export class PublicError extends Error {
  code!: HttpCode
}

export class PublicErrorNotFound extends PublicError {
  code = HTTP_CODES.NOT_FOUND

  constructor(message = `common:errors.api.${HTTP_CODES.NOT_FOUND}`) {
    super(message)
  }
}

export class PublicErrorForbidden extends PublicError {
  code = HTTP_CODES.FORBIDDEN

  constructor(message = `common:errors.api.${HTTP_CODES.FORBIDDEN}`) {
    super(message)
  }
}

export class PublicErrorConflict extends PublicError {
  code = HTTP_CODES.CONFLICT

  constructor(message = `common:errors.api.${HTTP_CODES.CONFLICT}`) {
    super(message)
  }
}

export class PublicErrorInternalServerError extends PublicError {
  code = HTTP_CODES.BAD_GATEWAY

  constructor(
    message = `common:errors.api.${HTTP_CODES.INTERNAL_SERVER_ERROR}`,
  ) {
    super(message)
  }
}
