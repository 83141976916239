"use client"

import "@cyna/components/shadcn-ui.css"
import "@cyna/web/i18n"

import { GeistSans as font } from "geist/font/sans"
import { ThemeProvider } from "next-themes"
import { NuqsAdapter } from "nuqs/adapters/next/app"
import { ReactNode, Suspense } from "react"

import {
  DEFAULT_LANGUAGE,
  Language,
  SUPPORTED_LANGUAGES,
} from "@cyna/common/constants"
import { SidebarProvider } from "@cyna/components/ui/sidebar"
import { cn } from "@cyna/components/utils"
import { LANGUAGE_LOCAL_STORAGE_KEY } from "@cyna/web/constants"
import { ApiClientProvider } from "@cyna/web/providers/ApiClientProvider"

// Language redirection should happen ASAP: skipping React
const langRegex = new RegExp(`^/(${SUPPORTED_LANGUAGES.join("|")})/?.*$`, "ui")

;(() => {
  if (typeof window === "undefined") {
    return
  }

  const fullPathWithSearch = window.location.href.slice(
    window.location.origin.length,
  )

  if (fullPathWithSearch.startsWith("/ping")) {
    return
  }

  if (langRegex.exec(fullPathWithSearch)) {
    return
  }

  const browserLang =
    (localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) as Language | null) ??
    (navigator.language.slice(2) as Language)
  const lang = SUPPORTED_LANGUAGES.includes(browserLang)
    ? browserLang
    : DEFAULT_LANGUAGE

  window.location.href = `/${lang}${fullPathWithSearch}`
})()

const RootLayout = ({
  children,
}: Readonly<{
  children: ReactNode
}>) => (
  <html className="bg-background text-foreground">
    <head>
      <title>CyberHub by CYNA</title>
    </head>
    <body className={cn(font.className)}>
      <Suspense>
        <NuqsAdapter>
          <ThemeProvider attribute="class" defaultTheme="system">
            <div className="relative h-screen max-h-screen min-h-screen">
              <ApiClientProvider>
                <SidebarProvider>{children}</SidebarProvider>
              </ApiClientProvider>
            </div>
          </ThemeProvider>
        </NuqsAdapter>
      </Suspense>
    </body>
  </html>
)

export default RootLayout
