import { Language } from "@cyna/common/constants"
import { RoutePath, Routes, routes } from "@cyna/common/routes"
import { useRouter as useNextRouter, useParams } from "next/navigation"
import { useCallback, useMemo } from "react"

export const useRouter = (forceLanguage?: Language) => {
  const { lang } = useParams<{ lang: Language }>()
  const router = useNextRouter()
  const language = forceLanguage ?? lang
  const generateUrl = useCallback(
    <TRoutePath extends RoutePath>(
      path: TRoutePath,
      ...options: Parameters<Routes[TRoutePath]>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
    ) => `/${language}${routes[path](...options)}`,
    [language],
  )
  const customRouter = useMemo(
    () => ({
      replace: (...args: Parameters<typeof generateUrl>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        router.replace(generateUrl(...args))
      },
      push: (...args: Parameters<typeof generateUrl>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        router.push(generateUrl(...args))
      },
    }),
    [generateUrl, router],
  )

  return customRouter
}
