import { flattenObject } from "@cyna/common/flattenObject"
import { UUID } from "crypto"
import { Primitive } from "zod"

export const APP_ENVS = {
  DEV: "development",
  TEST: "test",
  PROD: "production",
} as const

export const DEFAULT_LANGUAGE = "en"

export const SUPPORTED_LANGUAGES = ["en", "fr", "nl", "zh"] as const

export type Language = (typeof SUPPORTED_LANGUAGES)[number]

export const REPORT_TYPES = {
  ACTIVITY: "ACTIVITY",
  VULNERABILITY: "VULNERABILITY",
} as const

export type ReportType = keyof typeof REPORT_TYPES

export const DESKTOP_OPERATING_SYSTEMS = {
  WINDOWS: "Windows",
  LINUX: "Linux",
  MACOS: "macOS",
} as const

export const MOBILE_OPERATING_SYSTEMS = {
  ANDROID: "Android",
  IOS: "iOS",
  WINDOWS_PHONE: "Windows Phone",
} as const

export const HTTP_CODES = {
  // 1xx Informational
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,

  // 2xx Success
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,

  // 3xx Redirection
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,

  // 4xx Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  // 5xx Server Errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
} as const

export type HttpCode = (typeof HTTP_CODES)[keyof typeof HTTP_CODES]

export interface ApiErrorResponse<TData = unknown> {
  error: {
    message: string
    code: HttpCode
  }
  data?: TData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FileStream = any

export const SERVICES_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  PROCESSING: "PROCESSING",
} as const

export type ServiceStatus = keyof typeof SERVICES_STATUS

export const SERVICE_NAMES = {
  MS365: "MS365",
  MS365_DEFENDER: "MS365_DEFENDER",
  S1_INTERNAL: "S1_INTERNAL",
} as const

export type ServiceName = (typeof SERVICE_NAMES)[keyof typeof SERVICE_NAMES]

export const MANDATORY_SERVICES = [SERVICE_NAMES.S1_INTERNAL] as ServiceName[]

export const SERVICES_TYPES = {
  DIGITAL_WORKSPACES: "DIGITAL_WORKSPACES",
  EDR: "EDR",
} as const

export type ServiceType = (typeof SERVICES_TYPES)[keyof typeof SERVICES_TYPES]

export interface Service extends Record<string, unknown> {
  name: ServiceName
  status: ServiceStatus
}

export const SERVICES: Service[] = [
  {
    name: SERVICE_NAMES.MS365,
    status: SERVICES_STATUS.DISABLED,
  },
  {
    name: SERVICE_NAMES.MS365_DEFENDER,
    status: SERVICES_STATUS.DISABLED,
  },
  {
    name: SERVICE_NAMES.S1_INTERNAL,
    status: SERVICES_STATUS.DISABLED,
  },
] as const

// This differs from INTEGRATION_STATUS, as it defines
// the status of an instance of a service.
export const SERVICE_PROGRESS_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
} as const

export const TOTP_TOKEN_STATUS = {
  INVALID_TOKEN: "INVALID_TOKEN",
  VALID_TOKEN: "VALID_TOKEN",
} as const

export const TOTP_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
} as const

type GetPermissionsAsString<TPermissionMap> = {
  [TFeature in string & keyof TPermissionMap]:
    | TFeature
    | (TPermissionMap[TFeature] extends PermissionScope
        ? never
        : `${TFeature}.${GetPermissionsAsString<TPermissionMap[TFeature]>}`)
}[string & keyof TPermissionMap]

export const PERMISSION_SCOPES = {
  ALL: "ALL",
  CREATE: "CREATE",
  READ: "READ",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
} as const

export type PermissionScopes = typeof PERMISSION_SCOPES

export type PermissionScope = keyof PermissionScopes

export const PERMISSIONS_MAP = {
  cyna: "ALL" as PermissionScope,
  clients: {
    overview: "ALL" as PermissionScope,
    emergencyContacts: "ALL" as PermissionScope,
    reports: "ALL" as PermissionScope,
    services: {
      users: "ALL" as PermissionScope,
      secrets: "ALL" as PermissionScope,
    },
    settings: {
      information: "ALL" as PermissionScope,
      logo: "ALL" as PermissionScope,
      services: "ALL" as PermissionScope,
    },
    users: "ALL" as PermissionScope,
    distributionListItems: "ALL" as PermissionScope,
  },
  company: {
    apiKeys: "ALL" as PermissionScope,
    settings: {
      information: "ALL" as PermissionScope,
      logo: "ALL" as PermissionScope,
      services: "ALL" as PermissionScope,
    },
    users: "ALL" as PermissionScope,
  },
  session: "ALL" as PermissionScope,
}

export type Permission =
  `${GetPermissionsAsString<typeof PERMISSIONS_MAP>}.${PermissionScope}`

export interface SessionPayload {
  user: {
    id: UUID
    email: string
    firstName: string
    lastName: string
    role: Role
    language: Language
    company: {
      id: UUID
      name: string
      language: Language
    }
  }
}

export interface SessionTokenData {
  token: string
  expiresAt: string
  payload: SessionPayload
}

export type Prettify<T> = {
  [K in keyof T]: T[K] extends Primitive ? T[K] : Prettify<T[K]>
} & {}

export const COMPANY_RELATION_TYPES = {
  DISTRIBUTOR: "DISTRIBUTOR",
  MSP: "MSP",
  CUSTOMER: "CUSTOMER",
} as const

export type CompanyRelationTypes = typeof COMPANY_RELATION_TYPES

export type CompanyRelationType = keyof CompanyRelationTypes

export const ORDER_BY_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC",
} as const

export type OrderByDirection = keyof typeof ORDER_BY_DIRECTION

export type StringKey<K> = Extract<K, string>

export interface Assets {
  osName: string
  osVersion: string
  osType: "windows" | "linux" | "macos"
}

export type AssetsWithOutVersion = Omit<Assets, "osVersion">

export interface AssetsCount extends AssetsWithOutVersion {
  count: number
}

export interface AssetsEndOfLife extends Assets {
  endOfLife: string | false
}

export interface AssetsCountEndOfLife extends AssetsEndOfLife {
  count: number
}

export interface Alerts {
  name: string
  count: number
}

export interface CountAlerts {
  falsePositive: number
  truePositive: number
  ongoing: number
  quarantine: number
  total: number
}

export interface PercentageAlerts {
  falsePositive: string
  truePositive: string
  ongoing: string
  quarantine: string
  total: string
}

export interface CountAssets {
  servers: {
    windows: number
    linux: number
    macos: number
  }
  workstations: {
    windows: number
    linux: number
    macos: number
  }
  unidentified: number | undefined
}

export interface CountVulnerabilities {
  critical: number
  high: number
  medium: number
  low: number
}

export interface EdrData {
  assets: {
    count: CountAssets
    list: {
      servers: AssetsCount[]
      workstations: AssetsCount[]
      unidentified: AssetsCount[]
      serversObsolescence: AssetsCountEndOfLife[]
      workstationObsolescence: AssetsCountEndOfLife[]
    }
  }
  vulnerabilities: CountVulnerabilities | null
  alerts: {
    countAlertsCurrentMonth: CountAlerts
    countAlertsPreviousMonth: CountAlerts
    percentageEvolutionAlerts: PercentageAlerts
    topFiveAlerts: Alerts[]
  }
}

export type NoteWithLetter = "A" | "B" | "C" | "D" | "E"

export interface ReportNote {
  cyberScore: NoteWithLetter
  infraNote: NoteWithLetter
  vulnerabilityNote: NoteWithLetter | null
  lastMonthInfraNote: NoteWithLetter | "N/A"
  lastMonthVulnerabilityNote: NoteWithLetter | "N/A"
}

export interface ReportData extends EdrData {
  notesReport: ReportNote
}

export const EDR_NAMES = {
  S1_INTERNAL: "S1_INTERNAL",
  SOPHOS: "SOPHOS",
  MS365_DEFENDER: "MS365_DEFENDER",
} as const

export type EDRNames = typeof EDR_NAMES

export type EDRName = keyof typeof EDR_NAMES

export const REST_STATUS = {
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
} as const

export const LANGUAGE_NAMES = {
  en: "English",
  fr: "Français",
  nl: "Nederlands",
  zh: "汉语",
} as const satisfies Record<Language, string>

export const MINI_TYPES_LOGO_COMPANY = [
  "image/png",
  "image/jpeg",
  "image/jpg",
] as [string, ...string[]]

export const EMERGENCY_CONTACTS_PROGRESS_STATUS_DONE = "DONE"

export const BOOLEANS_AS_STRING = {
  TRUE: "TRUE",
  FALSE: "FALSE",
} as const

export const MINIMUM_NUMBER_OF_EMERGENCY_CONTACTS = 1

export const MINIMUM_NUMBER_OF_DISTRIBUTION_LIST_ADDRESSES = 1

export const COMPANY_SIZE = {
  LESS_THAN_25: "LESS_THAN_25",
  BETWEEN_25_AND_100: "BETWEEN_25_AND_100",
  BETWEEN_100_AND_250: "BETWEEN_100_AND_250",
  MORE_THAN_250: "MORE_THAN_250",
} as const

export type CompanySize = keyof typeof COMPANY_SIZE

export const BUSINESS_SECTORS = {
  CONSUMER_GOODS: "CONSUMER_GOODS",
  ENERGY: "ENERGY",
  REAL_ESTATE: "REAL_ESTATE",
  INDUSTRY: "INDUSTRY",
  NOT_FOR_PROFIT: "NOT_FOR_PROFIT",
  MATERIALS: "MATERIALS",
  DIGITAL_PRODUCTS_AND_SERVICES: "DIGITAL_PRODUCTS_AND_SERVICES",
  PROFESSIONAL_SERVICES: "PROFESSIONAL_SERVICES",
  FINANCIAL_SERVICES: "FINANCIAL_SERVICES",
  TELECOMMUNICATIONS_SERVICES: "TELECOMMUNICATIONS_SERVICES",
  HEALTHCARE: "HEALTHCARE",
  OTHER: "OTHER",
} as const

export type BusinessSector = keyof typeof BUSINESS_SECTORS

export const NAMESPACES = [
  "account",
  "apiErrors",
  "clients",
  "common",
  "company",
  "public",
] as const

export type Namespace = (typeof NAMESPACES)[number]

export const ROLES = {
  CYNA_ADMIN: "CYNA_ADMIN",
  MSP_ADMIN: "MSP_ADMIN",
  MSP_SALES: "MSP_SALES",
  MSP_TECH: "MSP_TECH",
  MANAGED_MSP_ADMIN: "MANAGED_MSP_ADMIN",
  MANAGED_MSP_SALES: "MANAGED_MSP_SALES",
  MANAGED_MSP_TECH: "MANAGED_MSP_TECH",
} as const

export type Roles = typeof ROLES

export type Role = keyof Roles

export const PERMISSIONS_BY_ROLE: Record<Role, Permission[]> = {
  [ROLES.CYNA_ADMIN]: ["cyna.ALL"],
  [ROLES.MSP_ADMIN]: ["clients.ALL", "company.ALL"],
  [ROLES.MSP_SALES]: [
    "clients.overview.ALL",
    "clients.settings.information.ALL",
    "clients.settings.logo.ALL",
    "clients.users.ALL",
  ],
  [ROLES.MSP_TECH]: [
    "clients.overview.ALL",
    "clients.emergencyContacts.ALL",
    "clients.services.ALL",
    "clients.settings.information.ALL",
    "clients.settings.logo.ALL",
    "clients.users.ALL",
    "clients.distributionListItems.ALL",
  ],
  [ROLES.MANAGED_MSP_ADMIN]: [
    "clients.overview.READ",
    "clients.emergencyContacts.ALL",
    "clients.reports.ALL",
    "clients.services.ALL",
    "clients.settings.information.ALL",
    "clients.settings.logo.ALL",
    "clients.settings.services.ALL",
    "clients.users.ALL",
    "clients.distributionListItems.ALL",
    "company.ALL",
  ],
  [ROLES.MANAGED_MSP_SALES]: [
    "clients.overview.READ",
    "clients.settings.information.ALL",
    "clients.settings.logo.ALL",
    "clients.users.ALL",
  ],
  [ROLES.MANAGED_MSP_TECH]: [
    "clients.overview.READ",
    "clients.emergencyContacts.ALL",
    "clients.reports.ALL",
    "clients.services.ALL",
    "clients.settings.information.ALL",
    "clients.settings.logo.ALL",
    "clients.users.ALL",
    "clients.distributionListItems.ALL",
  ],
} as const

export const ROLE_HIERARCHIES = {
  [ROLES.CYNA_ADMIN]: [
    ROLES.CYNA_ADMIN,
    ROLES.MSP_ADMIN,
    ROLES.MSP_SALES,
    ROLES.MSP_TECH,
    ROLES.MANAGED_MSP_ADMIN,
    ROLES.MANAGED_MSP_SALES,
    ROLES.MANAGED_MSP_TECH,
  ],
  [ROLES.MSP_ADMIN]: [ROLES.MSP_ADMIN, ROLES.MSP_SALES, ROLES.MSP_TECH],
  [ROLES.MSP_SALES]: [ROLES.MSP_SALES, ROLES.MSP_TECH],
  [ROLES.MSP_TECH]: [ROLES.MSP_TECH],
  [ROLES.MANAGED_MSP_ADMIN]: [
    ROLES.MANAGED_MSP_ADMIN,
    ROLES.MANAGED_MSP_SALES,
    ROLES.MANAGED_MSP_TECH,
  ],
  [ROLES.MANAGED_MSP_SALES]: [ROLES.MANAGED_MSP_SALES, ROLES.MANAGED_MSP_TECH],
  [ROLES.MANAGED_MSP_TECH]: [ROLES.MANAGED_MSP_TECH],
} as const

const permissionScopes = Object.values(PERMISSION_SCOPES)

export const permissions = flattenObject(
  PERMISSIONS_MAP,
  permissionScopes,
) as Permission[]

export const USERS_PROGRESS_STATUS_DONE = "DONE"

export const DEFAULT_USER = {
  passwordHash: "AAAAAAAAAAAAAAAAAAAAAAAAAA90".padEnd(64, "0"),
  passwordSalt: "AAAAAAAAAAAAAAAAAAAAAAAAAA90".padEnd(64, "0"),
  totpSecret: "AAAAAAAAAAAAAAAAAAAAAAAAAA90".padEnd(64, "0"),
} as const

export const SAFE_SLUG_PREFIX = "safeslugprefix"

export const S1_MSP_TAG = "MSP-"

export const S1_MSP_ROLES = {
  [`${S1_MSP_TAG}Viewer`]: "2067523746115789413",
  [`${S1_MSP_TAG}Tech`]: "2103781934859245584",
  [`${S1_MSP_TAG}Admin`]: "2071808321326002561",
} as const

export type S1MspRolesType = keyof typeof S1_MSP_ROLES

export const API_SCOPE_TYPE = {
  CLIENTS: "clients",
  COMPANY: "company",
} as const

export type ApiScopeType = keyof typeof API_SCOPE_TYPE

export const SOC_PREFIX = "SOC"

export const MAILGUN_ACCESS_LEVEL = {
  MEMBERS: "members",
  EVERYONE: "everyone",
  READONLY: "readonly",
} as const

export type MailgunAccessLevel =
  (typeof MAILGUN_ACCESS_LEVEL)[keyof typeof MAILGUN_ACCESS_LEVEL]

export const SENTINELONE_GROUPS = [
  {
    name: "Windows-Workstations",
    machineTypes: ["desktop", "laptop"],
    osTypes: ["windows"],
  },
  {
    name: "Windows-Servers",
    machineTypes: ["server"],
    osTypes: ["windows"],
  },
  {
    name: "Linux-Workstations",
    machineTypes: ["desktop", "laptop"],
    osTypes: ["linux"],
  },
  {
    name: "Linux-Servers",
    machineTypes: ["server"],
    osTypes: ["linux"],
  },
  {
    name: "MacOS-Workstations",
    machineTypes: ["desktop", "laptop"],
    osTypes: ["macos"],
  },
] as const

export const HYPHEN_CHARACTER = "\u00ad"

export type MachineType = "workstation" | "server" | "unidentified"

export type OsType = "windows" | "linux" | "macos"

export type ServerType = "windows" | "linux"
