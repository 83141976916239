"use client"

import { ApiRoutes } from "@cyna/api/index"
import { commonConfig } from "@cyna/common/config"
import {
  ApiErrorResponse,
  ORDER_BY_DIRECTION,
  OrderByDirection,
  SessionTokenData,
} from "@cyna/common/constants"
import { AUTH_ERRORS } from "@cyna/common/errors"
import { webConfig } from "@cyna/web/config"
import { useRouter } from "@cyna/web/hooks/useRouter"
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation as useReactMutation,
  useQuery as useReactQuery,
} from "@tanstack/react-query"
import {
  ClientRequestOptions,
  ClientResponse,
  hc,
  InferRequestType,
  InferResponseType,
} from "hono/client"
import {
  createParser,
  parseAsInteger,
  parseAsStringLiteral,
  useQueryState,
  useQueryStates,
} from "nuqs"
import { ReactNode, useCallback, useEffect, useState } from "react"

const { storageTokenHeader, sessionHeaderPrefix } =
  commonConfig.security.session
const saveStorageTokenFromHeader = (headers: Headers) => {
  // It's a JSON containing both the token and it's expiration date.
  // See `auth()` middleware for more details.
  const tokenData = headers.get(storageTokenHeader)

  if (!tokenData) {
    return
  }

  localStorage.setItem(storageTokenHeader, tokenData)
}

export type HonoClientFunction =
  | ((
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      args: any,
      options?: ClientRequestOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<ClientResponse<any, number, "json">>)
  | ((
      // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      args?: {},
      options?: ClientRequestOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<ClientResponse<any, number, "json">>)

export const queryClient = new QueryClient({
  // Query caching disable
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0,
    },
  },
})

export const apiClient = hc<ApiRoutes>(webConfig.apiBaseUrl, {
  fetch: (input: Parameters<typeof fetch>[0], requestInit?: RequestInit) => {
    const data = localStorage.getItem(storageTokenHeader) ?? ""
    const headers = (() => {
      try {
        const { token } = JSON.parse(data) as SessionTokenData

        return { Authorization: `${sessionHeaderPrefix} ${token}` }
      } catch (err) {
        // eslint-disable-next-line consistent-return, no-useless-return
        return
      }
    })()

    return fetch(input, {
      ...requestInit,
      headers: {
        ...Object.fromEntries(
          (requestInit?.headers as Headers | undefined)?.entries() ?? [],
        ),
        ...headers,
      },
      credentials: "include",
    })
  },
})

export const ApiClientProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

export const formatQueryKey = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequest extends { $get: HonoClientFunction; $url: (args?: any) => URL },
>(
  request: TRequest,
  queryArgs?: Omit<
    UndefinedInitialDataOptions<
      InferResponseType<TRequest["$get"]>,
      ApiClientError
    >,
    "queryKey" | "queryFn"
  > &
    InferRequestType<TRequest["$get"]>,
) => [request.$url().toString(), JSON.stringify(queryArgs)].filter((x) => x)

export const usePageQuery = (defaultPageIndex = 0, key = "page") =>
  useQueryState(
    key,
    parseAsInteger
      .withDefault(defaultPageIndex)
      .withOptions({ history: "push" }),
  )

export const usePagination = (defaultPageIndex?: number) => {
  const [pageIndex, setPageIndex] = usePageQuery(defaultPageIndex)
  const [pageSize, setPageSize] = useState(1)
  const updatePageSize = useCallback((count = 0) => {
    setPageSize(Math.max(Math.ceil(count / commonConfig.app.itemsPerPage), 1))
  }, [])
  const goToPage = useCallback(
    (targetPageIndex: string | number) => {
      const newPageIndex =
        typeof targetPageIndex === "string"
          ? Number.parseInt(targetPageIndex, 10)
          : targetPageIndex

      if (newPageIndex > pageSize) {
        return
      }

      void setPageIndex(newPageIndex)
    },
    [pageSize, setPageIndex],
  )
  const goToPreviousPage = useCallback(() => {
    void setPageIndex((currentPageIndex) => {
      if (currentPageIndex - 1 < 0) {
        return currentPageIndex
      }

      return currentPageIndex - 1
    })
  }, [setPageIndex])
  const goToNextPage = useCallback(() => {
    void setPageIndex((currentPageIndex) => {
      if (currentPageIndex + 1 >= pageSize) {
        return currentPageIndex
      }

      return currentPageIndex + 1
    })
  }, [setPageIndex, pageSize])

  return {
    pageIndex,
    pageSize,
    updatePageSize,
    goToPage,
    goToPreviousPage,
    goToNextPage,
  }
}

export const useOrderBy = <TItem extends Record<string, unknown>>(
  defaultOrderByKey: keyof TItem = "createdAt",
  defaultOrderByDirection: OrderByDirection = ORDER_BY_DIRECTION.DESC,
) => {
  const [{ orderByKey, orderByDirection }, setter] = useQueryStates(
    {
      orderByKey: createParser({
        parse(value: string) {
          return value
        },
        serialize(value: keyof TItem | null) {
          return String(value ?? "")
        },
      }).withDefault(defaultOrderByKey),
      orderByDirection: parseAsStringLiteral<OrderByDirection>(
        Object.keys(ORDER_BY_DIRECTION) as OrderByDirection[],
      ).withDefault(defaultOrderByDirection),
    },
    { history: "push" },
  )
  const orderBy = useCallback(
    (key: keyof TItem, direction: OrderByDirection) => {
      void setter({
        orderByKey: key,
        orderByDirection: direction,
      })
    },
    [setter],
  )
  const toggleOrderBy = useCallback(
    (forceKey?: string) => {
      void setter((value) => ({
        orderByKey: forceKey ?? value.orderByKey,
        orderByDirection:
          value.orderByDirection === ORDER_BY_DIRECTION.ASC
            ? ORDER_BY_DIRECTION.DESC
            : ORDER_BY_DIRECTION.ASC,
      }))
    },
    [setter],
  )

  return {
    key: orderByKey,
    direction: orderByDirection,
    orderBy,
    toggleOrderBy,
  }
}

export const useQuery = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequest extends { $get: HonoClientFunction; $url: (args?: any) => URL },
>(
  request: TRequest,
  queryArgs?: Omit<
    UndefinedInitialDataOptions<
      InferResponseType<TRequest["$get"]>,
      ApiClientError
    >,
    "queryKey" | "queryFn"
  > &
    InferRequestType<TRequest["$get"]>,
) => {
  const router = useRouter()
  const queryKey = formatQueryKey(request, queryArgs)
  const queryResult = useReactQuery<
    Exclude<InferResponseType<TRequest["$get"]>, { error: unknown }>,
    ApiClientError
  >({
    queryKey,
    queryFn: async () => {
      const response = await request.$get(queryArgs)

      saveStorageTokenFromHeader(response.headers)

      if (response.ok) {
        return response.json() as Promise<
          Exclude<InferResponseType<TRequest["$get"]>, { error: unknown }>
        >
      }

      const error = (await response.json()) as ApiErrorResponse

      if (
        (
          [
            AUTH_ERRORS.AUTH_INVALID_SESSION.message,
            AUTH_ERRORS.AUTH_MISSING_AUTHORIZATION_HEADER.message,
            AUTH_ERRORS.AUTH_COOKIE_NOT_FOUND.message,
          ] as string[]
        )
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          .includes(error.error?.message)
      ) {
        router.push("/session/sign-in", {
          returnTo: window.location.href.slice(window.location.origin.length),
        })
      }

      throw new ApiClientError(error.error)
    },
    placeholderData: keepPreviousData,
    ...queryArgs,
  })
  const isReady = !(queryResult.isFetching || queryResult.isLoading)

  return {
    ...queryResult,
    invalidateQuery: () => queryClient.invalidateQueries({ queryKey }),
    isReady,
  }
}

export const usePaginatedQuery = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRequest extends { $get: HonoClientFunction; $url: (args?: any) => URL },
>(
  request: TRequest,
  queryArgs?: Omit<
    UndefinedInitialDataOptions<
      InferResponseType<TRequest["$get"]>,
      ApiClientError
    >,
    "queryKey" | "queryFn"
  > &
    InferRequestType<TRequest["$get"]>,
  options?: {
    defaults?: {
      pageIndex?: number
      orderByKey?: string
      orderByDirection?: OrderByDirection
    }
  },
) => {
  const pagination = usePagination(options?.defaults?.pageIndex)
  const orderBy = useOrderBy<InferResponseType<TRequest["$get"]>["data"][0]>(
    options?.defaults?.orderByKey,
    options?.defaults?.orderByDirection,
  )
  const queryResult = useQuery(request, {
    ...queryArgs,
    query: {
      ...(queryArgs?.query as { pageIndex: number }),
      pageIndex: pagination.pageIndex,
      orderByKey: orderBy.key,
      orderByDirection: orderBy.direction,
    },
  } as typeof queryArgs)
  const count =
    (queryResult as { data?: { meta?: { count?: number } } }).data?.meta
      ?.count ?? 0
  const { updatePageSize } = pagination

  useEffect(() => {
    updatePageSize(count)
  }, [count, updatePageSize])

  return { ...queryResult, pagination, orderBy }
}

export const useMutation = <TRequest extends HonoClientFunction>(
  request: TRequest,
  mutationArgs?: Omit<
    UseMutationOptions<
      Exclude<InferResponseType<TRequest>, { error: unknown }>,
      ApiClientError,
      InferRequestType<TRequest>
    >,
    "mutationFn" | "mutationKey"
  >,
) => {
  const router = useRouter()
  const { mutateAsync, ...rest } = useReactMutation({
    mutationFn: async (variables) => {
      const response = await request(variables)

      saveStorageTokenFromHeader(response.headers)

      if (response.ok) {
        return response.json() as Promise<
          Exclude<InferResponseType<TRequest>, { error: unknown }>
        >
      }

      const error = (await response.json()) as ApiErrorResponse

      if (
        (
          [
            AUTH_ERRORS.AUTH_INVALID_SESSION.message,
            AUTH_ERRORS.AUTH_MISSING_AUTHORIZATION_HEADER.message,
            AUTH_ERRORS.AUTH_COOKIE_NOT_FOUND.message,
          ] as string[]
        )
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          .includes(error.error?.message)
      ) {
        router.push("/session/sign-in", {
          returnTo: window.location.href.slice(window.location.origin.length),
        })
      }

      throw new ApiClientError(error.error)
    },
    ...mutationArgs,
  })

  return [mutateAsync, rest] as const
}

export class ApiClientError extends Error {
  code = 0

  constructor({ message, code }: { message: string; code: number }) {
    super(message)

    this.code = code
  }
}
